export default {
    name: "com-configFilterTable",
    props: {
        title: {
            type: String,
            default: ""
        },
        filterFormConfig: Array,
        topOperation: Object,
        hasOperationInline: {
            type: Boolean,
            default: false
        },
        topRightOperationLine: {
            type: Boolean,
            default: false
        },
        tableColumn: Array,
        queryHandle: Function,
        services: String,
        dataFormatHandle: Function,
        filterLabelWidth: { type: String | Number, default: "" },
        filterCol: Number,
        filterInline: {
            default: true,
            type: Boolean
        },
        labelPosition: String,
        pagination: {
            type: Boolean,
            default: true
        },
        showFormFilter: {
            type: Boolean,
            default: true
        },
        highlightCurrentRow: {
            type: Boolean,
            default: false
        },
        showSummary: {
            type: Boolean,
            default: false
        },
        rowClassName: {
            type: Function
        },
        showRequestNum: {
            type: Boolean,
            default: true
        },
        spanMethod: {
            type: Function
        },
        formData: {
            type: Object,
            default: () => {}
        },
        importData: {
            type: Array,
            default: () => []
        },
        showRightBtns: {
            type: Boolean,
            default: false
        },
        sumMethod: {
            type: Function
        },
        setRow: {
            type: Boolean,
            default: false
        }, //开启行点击事件
        setCell:{//开启单元格点击事件
            type:Boolean,
            default:false
        },
        defaultFilterData: {
            type: Object,
            default: () => {}
        }, //默认表格搜索条件
        params: {
            type: Object,
            default: () => {}
        },
        openValid: {
            type: Boolean,
            default: false
        }, //是否开启提交前验证
        saveBtnDisabled: {
            type: Boolean,
            default: false
        },
        height: {
            type: String|Number
        },
        showRightArea: {
            type: Boolean,
            default: false
        },
        splitRatio: {
            //右屏比例0-24
            type: Number,
            default: 0
        },
        formDataFormat: {
            //筛选条件格式化
            type: Function
        },
        showTopArea:{
            //显示表格上方区域
            type:Boolean,
            default:false
        },
        resetPager:{
            //查询按钮重置页码
            type:Boolean,
            default:false
        },
        updatedSign:{
            //筛选表格加载完成
            type:Boolean,
            default:false
        }
    },
    data() {
        return {
            onQueryFilterData: null,
            selectionList: [],
            filteData: null,
            stringHandleMap: {
                resetFilterForm: () => {
                    this.resetFilterForm();
                },
                query: async () => {
                    if (this.openValid) {
                        let formData = this.getFormData();
                        this.$emit("validateRules", formData);
                    } else {
                        if(this.resetPager){
                            this.$refs.table.pager={
                                pageNo: 1,
                                pageSize: 10
                            };
                        }
                        this.filterFormQueryHandle();
                    }
                }
            },
            handleArgumentMap: {
                batchSelected: () => {
                    return this.selectionList;
                },
                filterForm: () => {
                    return this.filteData;
                },
                configTable: () => {
                    return this.$refs.table;
                },
                commonForm: () => {
                    return this.$refs.filterForm;
                }
            },
            tableData: {}
        };
    },
    computed: {
        hasSelection() {
            return Boolean(
                (this.tableColumn || []).filter(v => v.type === "select").length
            );
        },
        hasTopOperationLeft() {
            return (
                this.topOperation &&
                this.topOperation.left &&
                this.topOperation.left.length
            );
        },
        total() {
            let { tableData, importData } = this;
            if (!importData.length) {
                return tableData.total
                    ? tableData.total
                    : Array.isArray(tableData)
                        ? tableData.length
                        : "-";
            }
            return importData.length;
        }
    },
    methods: {
        operationHandle(operation = {}) {
            let handleArguments = [];

            if (
                operation.requireArguments &&
                operation.requireArguments.length
            ) {
                operation.requireArguments.forEach(argumentItem => {
                    handleArguments.push(
                        this.handleArgumentMap[argumentItem]()
                    );
                });
            }

            if (operation.handle) {
                if (typeof operation.handle === "string") {
                    return this.stringHandleMap[operation.handle](
                        ...handleArguments
                    );
                } else if (typeof operation.handle === "function") {
                    return operation.handle(...handleArguments);
                }
            }
        },
        resetFilterForm() {
            this.$refs.filterForm.resetFields();
            this.$emit("reset-filter-form");
        },
        async filterFormQueryHandle() {
            let success = await new Promise(resovle =>
                this.$refs.filterForm.validate(success => {
                    if (success) {
                        resovle(true);
                    } else {
                        resovle(false);
                    }
                })
            );
            if (!success) {
                return;
            }

            // eslint-disable-next-line one-var
            this.filteData = Object.assign({}, this.getFormData(), this.params);
            this.$nextTick(() => {
                this.$refs.table.request();
            });
        },
        refetch() {
            this.$refs.table.request();
        },
        async asyncRequest(){
            await this.$refs.table.request();
        },
        tableSelectionChangeHandle(list) {
            this.selectionList = list;
        },
        tableDataChange(data) {
            this.tableData = data;
            this.$emit("get-data-set", data);
        },
        tableCurrentChangeHandle(currentRow, oldCurrentRow) {
            this.$emit("current-change", currentRow, oldCurrentRow);
        },
        setCurrentRow(row) {
            this.$refs.table.setCurrentRow(row);
        },
        rowClick(row) {
            this.setRow && this.$emit("row-click", row);
        },
        cellClick(row, column, cell, event) {
            this.setCell && this.$emit("cell-click", {row, column, cell, event});
        },
        setFormData(data) {
            let { formData } = this.$refs.filterForm;
            this.$refs.filterForm.formData = Object.assign(formData, data);
        },
        getFormData() {
            let formData = { ...this.$refs.filterForm.formData };
            Object.keys(formData).forEach(key => {
                if (!formData[key] && formData[key] !== 0) {
                    delete formData[key];
                }
            });
            this.formDataFormat && (formData = this.formDataFormat(formData));
            return formData;
        },
        saveHandle() {
            this.$emit("save-handle");
        },
        toggleRowSelection(rows) {
            rows.forEach(row => {
                this.$refs.table.$refs.table.toggleRowSelection(row);
            });
        },
        doLayout(){
            this.$refs.table&&this.$refs.table.doLayout();
        },
        commonFormUpdated(formData){
            this.updatedSign&&this.$emit("common-form-updated",formData);
        }
    },
    created() {
        this.filteData = Object.assign({}, this.defaultFilterData, this.params);
    }
};
